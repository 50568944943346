import styled from "styled-components";
import { theme } from "@ifgengineering/component-library";
import MobileDisableScreen from "@assets/images/mobile-disable-screen.png";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 30px;
  // set MobileDisableScreen as background image
  background-image: url(${MobileDisableScreen});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${theme.colors.BLACK};
`;

export const Cur8Logo = styled.img`
  height: 24px;
  object-fit: contain;
`;
export const PoweredByLogo = styled.img`
  justify-content: flex-start;
  height: 33px;
`;

export const AppStoreImg = styled.img`
  display: flex;
  justify-content: flex-start;
  height: 46px;
  cursor: pointer;
  &:hover {
    transform: scale(1.07);
  }
  transition: all 0.3s ease;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  gap: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

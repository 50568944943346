import React, { useState } from "react";
import { Text } from "@ifgengineering/component-library";
import { clientInvestSdk } from "../../utils/clientInvestSdk";
import mobileZakatPreviewURL from "@assets/cur8/img/mobile-zakat-preview.png";
import { toast } from "react-toastify";
import { logException } from "../../helpers/logException/logException";
import { useSelector } from "react-redux";
import { investorSelector } from "../../state/investor/investor.selectors";
import QRCode from "../QRCode";
import {
  PageWrapper,
  ImageContainer,
  MobilePreview,
  TextContainer,
  LinksContainer,
  StyledLink,
  DownloadFromStoreButtonWrapper,
} from "./styled";
import { SITE_URL } from "gatsby-env-variables";
import { AppStoreImg } from "@components/BlockMobile/styled";
import DownloadAppStore from "@assets/images/download-on-app-store-dark.png";
import DownloadGooglePlay from "@assets/images/download-on-play-store-dark.png";

const ZakatPage: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const investor = useSelector(investorSelector);

  const handleDownloadStatement = async () => {
    if (isDownloading) return;

    try {
      setIsDownloading(true);
      const res = await clientInvestSdk.generateZakatStatement();
      const blob = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = "zakat_statement.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      toast.error("Something went wrong. Try again or contact support");
      logException({
        message: "Error on requesting Zakat Statement",
        scopeEmail: investor?.email,
        exception: e,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleWebCalculator = () => {
    window.open("https://zakat.cur8.capital/app/calculate", "_blank");
  };

  const scanQRCodeText = `Scan the QR code or search 'Cur8 by IFG' on Google Play or App Store.`;

  const qrCodeValue = `${SITE_URL}/download-app`;
  const appStoreURL = "https://apps.apple.com/gb/app/cur8-by-ifg/id1665655843";
  const playStoreURL =
    "https://play.google.com/store/apps/details?id=capital.cur8&hl=en_US";

  const redirectToStore = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <PageWrapper>
      <ImageContainer>
        <div>
          <MobilePreview src={mobileZakatPreviewURL} alt="Mobile App Preview" />
        </div>
        <QRCode value={qrCodeValue} size={160} />
        <DownloadFromStoreButtonWrapper>
          <AppStoreImg
            src={DownloadAppStore}
            onClick={() => redirectToStore(appStoreURL)}
          />
          <AppStoreImg
            src={DownloadGooglePlay}
            onClick={() => redirectToStore(playStoreURL)}
          />
        </DownloadFromStoreButtonWrapper>
      </ImageContainer>

      <TextContainer>
        <Text
          type="H32"
          color="SLATE900"
          style={{
            marginBottom: "8px",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "40px",
          }}
        >
          Introducing: A Better Way to Calculate Zakat—Now on Our Mobile App
        </Text>

        <Text
          type="H14"
          color="SLATE600"
          style={{
            marginBottom: "16px",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {scanQRCodeText}
        </Text>
      </TextContainer>

      <LinksContainer>
        <StyledLink onClick={handleDownloadStatement} href="#">
          Download zakat statement for my Cur8 investments
        </StyledLink>

        <StyledLink onClick={handleWebCalculator} href="#">
          Use zakat calculator on web
        </StyledLink>
      </LinksContainer>
    </PageWrapper>
  );
};

export default ZakatPage;

import styled from "styled-components";
import { theme, ColorType } from "@ifgengineering/component-library";
import { switchProp } from "styled-tools";

export type WrapperWidth = "COMPACT" | "NARROW" | "LARGE" | "FULL";

export const PageWrapper = styled.div<{ background?: ColorType }>`
  background: ${({ background }) =>
    background ? theme.colors[background] : theme.colors["SLATE200"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
`;

export const ContentWrapper = styled.div<{
  width?: WrapperWidth;
  padding?: string;
}>`
  width: 100%;
  padding: ${({ padding }) => padding || "48px 0"};
  padding-top: 34px;
  max-width: ${switchProp("width", {
    COMPACT: "540px",
    NARROW: "944px",
    LARGE: "1200px",
    FULL: "100%",
  })};
`;

export const HeaderWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

import React, { useState } from "react";
import { Text, Stack, OutlinedButton } from "@ifgengineering/component-library";
import PageLayout from "../../layouts/PageLayout";
import { navigate } from "gatsby";
import styled from "styled-components";
import FundCyclesCalendarImage from "@assets/cur8/img/fund-cycles-calendar.png";
import Accordion from "@components/Accordion/Accordion.component";

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  padding-top: 8px;
`;

export const AccordionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ImageOverlayText = styled.div`
  position: absolute;
  bottom: 32px;
  left: 32px;
  z-index: 1;
`;

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`;

const CycleImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;

const FundCycles = (): JSX.Element => {
  const terms = [
    {
      title: "1. Open Date",
      content:
        "This is when the fund is made live on the platform for you to commit and invest in. Each fund is typically opened one to two months before the next deployment date. While you can commit early, your money won't start working for you until we deploy the funds.",
    },
    {
      title: "2. Transfer Deadline",
      content:
        "This is the date by which you must transfer your funds to be included in the next investment cycle. We cut off any transfers after this date. If you miss the deadline, your funds will be held until the next cycle.",
    },
    {
      title: "3. Deployment Date",
      content:
        "This is when we send your funds to the investment vehicle, and your money starts earning returns. This typically happens 1-2 weeks after the transfer deadline. During this time, we prepare final legal agreements and consolidate all investor funds.",
    },
    {
      title: "4. Withdrawal Deadline",
      content:
        "If you're an existing investor and want to withdraw your funds, you need to submit a request before the same transfer deadline. If you miss this, you'll need to wait until the next cycle.",
    },
  ];

  const [openTerms, setOpenTerms] = useState<Record<string, boolean>>(
    terms.reduce((acc, term) => ({ ...acc, [term.title]: true }), {})
  );

  const handleTermClick = (termId: string) => {
    setOpenTerms((prev) => ({
      ...prev,
      [termId]: !prev[termId],
    }));
  };

  return (
    <PageLayout
      width="NARROW"
      background="SLATE200"
      onGoBack={() => navigate(-1)}
    >
      <Stack orientation="vertical" gap={24}>
        <ImageContainer>
          <DarkOverlay />
          <ImageOverlayText>
            <Text type="H24" color="WHITE" fontFamily="archiabold">
              What are Fund Cycles?
            </Text>
          </ImageOverlayText>
          <CycleImage
            src={FundCyclesCalendarImage}
            alt="Fund Cycles Calendar"
          />
        </ImageContainer>

        <Text type="P16" color="SLATE800">
          We open our funds for new investment on a regular basis—normally every
          3 months. Between these dates, you can still transfer your funds at
          any time, but they will be held in reserve until the fund opens. Your
          funds will only start earning once they have been deployed in the
          investment vehicle.
        </Text>

        <Text type="H18" color="SLATE900">
          KEY TERMS
        </Text>

        <AccordionsWrapper>
          {terms.map((term, index) => (
            <Accordion
              key={index}
              title={term.title}
              titleFontSize="S16"
              titleColor="SLATE900"
              isOpen={openTerms[term.title]}
              onClick={() => handleTermClick(term.title)}
              bgColor="WHITE"
              padding={16}
              borderRadius={8}
            >
              <StyledText type="P14" color="SLATE600">
                {term.content}
              </StyledText>
            </Accordion>
          ))}
        </AccordionsWrapper>

        <Text type="P16" color="SLATE600">
          If you have any questions, feel free to reach out to our support team.
        </Text>

        <ButtonContainer>
          <OutlinedButton
            text="Go back"
            textColor="BLUE600"
            onClick={() => navigate(-1)}
            width="200px"
          />
        </ButtonContainer>
      </Stack>
    </PageLayout>
  );
};

export default FundCycles;

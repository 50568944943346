import React, { FC } from "react";
import { ColorType, LinkButton, Text } from "@ifgengineering/component-library";
import {
  WrapperWidth,
  ContentWrapper,
  PageWrapper,
  HeaderWrapper,
} from "./styled";

type Props = {
  children: React.ReactNode;
  background?: ColorType;
  title?: string;
  subtitle?: string;
  width?: WrapperWidth;
  onGoBack?: () => void;
  padding?: string;
  hideContent?: boolean;
};

const PageLayout: FC<Props> = ({
  children,
  background,
  title,
  subtitle,
  width = "NARROW",
  padding,
  onGoBack,
  hideContent,
}) => {
  return (
    <PageWrapper background={background}>
      <ContentWrapper width={width} padding={padding}>
        {!hideContent && (
          <HeaderWrapper>
            {onGoBack && (
              <LinkButton
                testId="back-button"
                text="Back"
                textSize="S18"
                textColor="BLUE600"
                icon="Left"
                iconSize={22}
                onClick={onGoBack}
              />
            )}
            <div>
              {title && (
                <Text type="H32" color={"SLATE900"} fontFamily="archiaregular">
                  {title}
                </Text>
              )}
              {subtitle && (
                <Text align="left" type="P18" color="SLATE900">
                  {subtitle}
                </Text>
              )}
            </div>
          </HeaderWrapper>
        )}
        {children}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default PageLayout;

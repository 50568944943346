import styled from "styled-components";

export const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  margin-bottom: 16px;
`;

export const MobilePreview = styled.img`
  width: 240px;
  height: auto;
`;

export const TextContainer = styled.div`
  max-width: 800px;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: #4f46e5;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    text-decoration: underline;
  }
`;

export const DownloadFromStoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

import { Deal } from "@ifgengineering/client-invest-sdk";
import { RootState } from "../rootReducer";

export const dealsSelector = (state: RootState): Deal[] => state.deal.data;
export const dealSelector = (state: RootState, dealId?: string): Deal | null =>
  state.deal.data.find((deal) => deal.id === dealId) || null;
export const isLoadingDealSelector = (state: RootState): boolean =>
  state.deal.loading;
export const neverLoadedDealSelector = (state: RootState): boolean =>
  state.deal.neverLoadedDeals;

import React from "react";
import { QRCodeCanvas } from "qrcode.react";

interface QRCodeProps {
  value: string;
  size?: number;
}

const QRCode: React.FC<QRCodeProps> = ({ value, size = 128 }) => {
  return <QRCodeCanvas value={value} size={size} level="H" />;
};

export default QRCode;
